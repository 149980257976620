
import { defineComponent, PropType } from "vue";

export interface SettingsOfPriceChange {
  changeAllIfEqual: boolean;
  changeToHighestPrice: boolean;
}

import { ref, watch } from "vue";

export default defineComponent({
  name: "price-settings-dropdown",
  props: {
    Settings:  { type: Object as PropType<SettingsOfPriceChange>, required: true },
  },
  setup(props,{emit}) {
    // Create a reactive copy of Settings prop
    const settingsCopy = ref({ ...props.Settings });

    // Watch for changes in the copy and emit an event when it changes
    watch(settingsCopy, (newSettings) => {
      if (newSettings.changeAllIfEqual !== props.Settings.changeAllIfEqual || newSettings.changeToHighestPrice !== props.Settings.changeToHighestPrice) {
        emit("updated", newSettings);
      }
    });
    const updateParent =()=>{
      emit("updated", settingsCopy.value);

    } 
    return {
      settingsCopy,
      updateParent,
    };
  },
});

