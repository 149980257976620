
import { defineComponent, onMounted, onBeforeMount, ref } from "vue";
import ProductPrice from "@/components/widgets/tables/ProductPrice.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { ProductNGDTO } from "@/dtos/ProductNGDTO";
export default defineComponent({
  name: "dashFard",
  components: {
    ProductPrice,
  },
  setup() {
    const data = ref();
    const store = useStore();
    const refreshData =  async () => {
      await store.dispatch(Actions.PRODUCTS, "token").then((response: any) => {
        //console.log(response);
        data.value = response.data.products;
        for (let index = 0; index < data.value?.length; index++) {
          const element = data.value[index] as ProductNGDTO;
          if(element.integrationProducts)
          for (let k = 0 ; k < element.integrationProducts?.length??0;k++){
            const iProduct = element.integrationProducts[k];
            if(iProduct.product)
              iProduct.product.NewPrice = iProduct.product?.Price;

          }
        }
      });
    };
    onBeforeMount(async () => {
      refreshData();
    });

    onMounted(async () => {
      setCurrentPageTitle("Ürün Fiyat Değiştirme");
    });
    return {
      data,
      refreshData
    };
  },
});
